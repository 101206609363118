<!--
  ====DESCRIPTION====
    This is tab content core class that holds all tab content components.
    Used from main component TabsLayout.vue.

  ====GENERATION====
    Generate components that are passed via configuration.
    Each dynamic component accepts shared properties that are common for all of those.
    Here is some events that each dynamic component can emit to manage shared properties.
-->

<template>
  <div
    class="tab-content-div"
  >
    <!--    Loading spinner button-->
    <v-overlay
      v-if="isLoadingData"
      style="left: 12px; bottom: 12px;"
      opacity="0.2"
      absolute
    >
      <v-progress-circular
        indeterminate
        :color="'var(--v-primary-base)'"
        size="60"
      />
    </v-overlay>

    <template
      v-for="(config, configIndex) in tab.tabContent"
    >
    <!--    Dynamic component with shared props and events-->
      <component
        v-if="config && config.component"
        :is="config.component"
        :key="configIndex"
        :tab="tab"
        :form-data="formDataForSend"
        :edit="edit"
        :permissions="permissions"
        :initial-autocompletes="initialAutocompletes"
        :fields-config="fieldsConfig"
        :validation-errors="validationErrors"
        :create-mode="createMode"
        :update-data="updateData"
        :update-validation-errors="updateValidationErrors"
        :config="config"
        :show-cancel-button="createMode"
        :reset-edit="resetEdit"
        :current-tab-id="currentTabId"
        :refresh-tabs="refreshTabs"
        :hide-view="hideView"
        @save-tab="saveTab"
        @cancel-tab-save="cancelTabSave"
        @set-edit="value => edit = value"
        @clear-validation-errors="clearValidationErrors"
        @reset-create-mode="$emit('reset-create-mode')"
        @hide-view="shouldHideView => hideView = shouldHideView"
        @lock-tabs="val => $emit('lock-tabs', val)"
        @reset-edit="$emit('reset-edit')"
      />
    </template>
  </div>
</template>

<script>
import { api } from '@/global/services/api'
import store from '@/global/store'
import { cloneDeep, isArray, isEmpty, isObject } from 'lodash'
import { convertFromPathToBlob } from '@/global/services/helpers/files'

export default {
  name: 'Content',

  props: {
    tab: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentTabId: {
      type: Number,
      default: 0
    },
    // Passed from main component TabsLayout.vue.
    // Indicate whether the user wants to leave tab that which is in edit mode (information popup)
    // This value is passed to the all components
    resetEdit: {
      type: Boolean,
      default: false
    },
    // Boolean value that tells us whether create mode is enabled/disabled
    createMode: {
      type: Boolean,
      default: true
    },
    // Component view name to which redirect after unsuccessfully fetching data
    redirectViewName: {
      type: String,
      default: ''
    },
    // Array of tabs keys that should be refreshed
    refreshTabs: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      isEditing: false,
      edit: false,
      formDataForSend: {},
      initialAutocompletes: {},
      originalFormData: {},
      // Holds fields config obtained from backend. Used in case where field must be disabled/enabled by backend side (in normal case this is handled by field config here in frontend)
      // For now, it is in shape: fieldKey: {}, where only 'editable' prop of object is used, expand in the future as needed
      // Example: name: { editable: true } -> field with key 'name' will be disabled
      fieldsConfig: {},
      validationErrors: {},
      isTabDataFetched: false,
      initialCall: false,
      // Each component can signal to other components whether they should hide themselves or remain visible
      hideView: false,
      // Used for checking whether some action can be performed or not
      permissions: null,
      // Loading spinner button show/hide indicator
      isLoadingData: false
    }
  },

  watch: {
    tab: {
      immediate: true,
      deep: true,
      async handler (tabItem) {
        if (!this.isTabDataFetched && !this.createMode) {
          this.isTabDataFetched = true
          await this.fetchTabData()

          if (!this.initialCall && tabItem && tabItem.additionalSetup && typeof tabItem.additionalSetup === 'function') {
            this.initialCall = true
            await tabItem.additionalSetup(this)
          }
        }
        else if (this.createMode) {
          if (!this.initialCall && tabItem && tabItem.additionalSetup && typeof tabItem.additionalSetup === 'function') {
            this.initialCall = true
            await tabItem.additionalSetup(this)
          }
        }
      }
    },
    edit: {
      handler (val) {
        this.$emit('lock-tabs', val)
      }
    },
    resetEdit: {
      handler (resetEdit) {
        if (resetEdit && this.edit) {
          this.edit = false
          this.formDataForSend = cloneDeep(this.originalFormData)
          this.$emit('reset-edit')
        }
      }
    },
    'tab.permissions': {
      deep: true,
      handler (newPermission) {
        if (newPermission) {
          this.permissions = newPermission
        }
      }
    },
    refreshTabs: {
      immediate: true,
      deep: true,
      async handler (tabsToRefresh) {
        const tabKey = this.tab?.key

        if (typeof tabKey === 'string' && tabsToRefresh?.includes(tabKey)) {
          await this.fetchTabData()

          if (this.tab && this.tab.additionalSetup && typeof this.tab.additionalSetup === 'function') {
            await this.tab.additionalSetup(this)
          }
        }
      }
    }
  },

  methods: {
    // Function that performs update form data globally. As we can have more components therefore we have some shared states like formData, permissions, ...etc.
    // Passed to each component and can be called from there.
    updateData (val) {
      this.formDataForSend = {
        ...this.formDataForSend,
        ...val
      }
    },

    // Function that is similar to the updateData, performs update validation errors data globally and can be called from each component
    updateValidationErrors (val) {
      this.validationErrors = {
        ...this.validationErrors,
        ...val
      }
    },

    async saveTab () {
      try {
        // Check if the API config is valid for saving
        if (!this.checkTabApiConfigForUpdatingData()) {
          this.pushNotification(this.$t('base/profile.invalid_api_configuration_save'))
          return
        }

        this.isLoadingData = true
        const modifiedData = await this.modifyFormData(this.formDataForSend)

        const { additionalDataManipulation, afterSave } = this.tab || {}
        if (additionalDataManipulation && typeof additionalDataManipulation === 'function') {
          additionalDataManipulation(modifiedData)
        }

        const creatableFormValues = this.applyFormValuesForSend(modifiedData)
        const formData = this.populateFormDataForSending(creatableFormValues)

        // If no form data, push error notification and return
        if (!formData) {
          this.pushNotification(this.$t('base/profile.save_error'))
          return
        }

        const { module, method } = this.tab?.apiConfig?.post || {}
        let { route } = this.tab?.apiConfig?.post

        if (route && typeof route === 'function') {
          route = route(modifiedData)
        }

        const { data } = await api()[module][method](
          route,
          formData
        )

        // Update the UI after a successful save
        this.edit = false
        this.refreshData(data)

        if (afterSave && typeof afterSave === 'function') {
          afterSave(this, data)
        }
        this.isLoadingData = false
      }
      catch (exception) {
        // Handle validation errors or general save errors
        this.handleSaveError(exception)
        console.log(exception)
        this.isLoadingData = false
      }
    },

    // Helper method for error handling
    handleSaveError (exception) {
      if (!exception || !exception.response) {
        this.pushNotification(this.$t('base/profile.save_error'))
        return
      }

      this.validationErrors = exception.response.data.errors
    },

    // Helper method to push notifications
    pushNotification (message) {
      store.dispatch('base/notifications/push', message)
    },

    async fetchTabData () {
      try {
        const tabItem = this.tab
        if (!this.checkTabApiConfigForFetchingData(tabItem)) {
          return
        }

        this.isLoadingData = true
        const { module, method } = tabItem?.apiConfig?.get
        let { route } = tabItem?.apiConfig?.get
        const { onDataReceived } = tabItem || {}

        if (route && typeof route === 'function') {
          route = route()
        }

        const apiData = await api()[module][method](route)
        const { data } = apiData || {}

        // Call onDataReceived callback
        if (onDataReceived && typeof onDataReceived === 'function') {
          onDataReceived(apiData)
        }
        this.refreshData(data)
        this.isLoadingData = false
      }
      catch (exception) {
        this.isLoadingData = false
        // If page doesn't exist, redirect to the provided view
        if (exception?.response?.status === 404 && this.redirectViewName) {
          await this.$router.push({ name: this.redirectViewName })
        }
        console.log(exception)
      }
    },

    cancelTabSave () {
      const { afterCancel } = this.tab || {}
      this.$emit('reset-create-mode')
      this.edit = false
      this.formDataForSend = cloneDeep(this.originalFormData)
      this.validationErrors = {}

      if (afterCancel && typeof afterCancel === 'function') {
        afterCancel(this)
      }
    },

    checkTabApiConfigForUpdatingData () {
      return this.tab &&
        !isEmpty(this.tab) &&
        this.tab.apiConfig?.post &&
        !isEmpty(this.tab.apiConfig.post) &&
        ['method', 'module', 'route'].every(key => this.tab.apiConfig.post[key])
    },

    checkTabApiConfigForFetchingData (tab) {
      return tab &&
        !isEmpty(tab) &&
        tab.apiConfig?.get &&
        !isEmpty(tab.apiConfig.get) &&
        ['method', 'module', 'route'].every(key => tab.apiConfig.get[key])
    },

    populateFormDataForSending (data) {
      const formData = new FormData()

      // If in edit mode (PATCH)
      if (!this.createMode) {
        formData.append('_method', 'PATCH')
      }

      // Recursive function to handle nested objects
      const appendFormData = (formData, fieldKey, fieldValue) => {
        if (fieldValue instanceof File) {
          // Handle File
          formData.append(fieldKey, fieldValue)
        }
        else if (Array.isArray(fieldValue)) {
          // Handle Array (recursively if needed)
          fieldValue.forEach((value, index) => {
            appendFormData(formData, `${fieldKey}[${index}]`, value)
          })
        }
        else if (typeof fieldValue === 'object' && fieldValue !== null) {
          // Handle nested object
          Object.keys(fieldValue).forEach(key => {
            appendFormData(formData, `${fieldKey}[${key}]`, fieldValue[key])
          })
        }
        else {
          // Handle primitive values (string, number, etc.)
          formData.append(fieldKey, fieldValue ?? '')
        }
      }

      // Iterate through the initial data object
      for (const fieldKey in data) {
        const fieldValue = data[fieldKey]
        appendFormData(formData, fieldKey, fieldValue)
      }

      return formData
    },

    async modifyFormData (formData) {
      const resultFormData = cloneDeep(formData)
      const autocompleteConfig = []

      if (this.tab && this.tab.tabContent && this.tab.tabContent.length) {
        // Handle files conversion
        await this.handleFilesConversion(this.tab, resultFormData)

        // Handle autocomplete values
        for (const tabCard of this.tab.tabContent) {
          // Columns tab card
          if (tabCard && tabCard.rows && tabCard.rows.length && tabCard.type === 'columns') {
            for (const row of tabCard.rows) {
              if (row && row.fields && row.fields.length) {
                for (const field of row.fields) {
                  this.getAutocompleteConfig(field, autocompleteConfig, field.key)
                }
              }
            }
          }
          // Dynamic columns tab card
          else if (tabCard && tabCard.type === 'dynamic-columns' && tabCard.fields && tabCard.fields.length) {
            for (const field of tabCard.fields) {
              this.getAutocompleteConfig(field, autocompleteConfig, tabCard.key)
            }
          }
        }
      }

      // Modify formData based on autocomplete or combobox config
      for (const config of autocompleteConfig) {
        for (const key in resultFormData) {
          // If final value should be an object with provided returnValueProps
          if (config.key === key && !config.formValue && config.return === 'object' && config.returnValueProps && config.returnValueProps.length) {
            resultFormData[key] = config.returnValueProps.reduce((acc, prop) => {
              if (prop in resultFormData[key]) {
                acc[prop] = resultFormData[key][prop]
              }
              return acc
            }, {})
          }
          // If final value should be some property of an object (formValue)
          else if (resultFormData[key] && !resultFormData[key].length && config.key === key && config.formValue) {
            resultFormData[key] = resultFormData[key][config.formValue]
          }
          // Recursively update nested values from an array
          else if (resultFormData[key] && resultFormData[key].length && config.key === key && config.formValue) {
            for (let i = 0; i < resultFormData[key].length; i++) {
              this.updateNestedValues(resultFormData[key][i], config.formValue)
            }
          }
        }
      }

      return resultFormData
    },

    updateNestedValues (obj, returnValue) {
      if (!Array.isArray(returnValue) || returnValue.length === 0) return

      const finalKey = returnValue[0]
      const nestedObject = returnValue.reduce((o, k) => (o ? o[k] : undefined), obj)

      if (nestedObject && typeof nestedObject === 'string') {
        // Update the value of the last key in the nested object
        obj[finalKey] = nestedObject
      }
      else if (Array.isArray(nestedObject) && nestedObject.length) {
        for (let i = 0; i < nestedObject.length; i++) {
          this.updateNestedValues(nestedObject[i], returnValue.slice(1))
        }
      }
    },

    getAutocompleteConfig (field, autocompleteConfig, fieldKey) {
      if ((field.type === 'autocomplete' || field.type === 'combobox') && field.key) {
        const { autocomplete_options: autocompleteOptions } = field || {}
        const { form_value: formValue, returnValue, returnValueProps } = autocompleteOptions || {}
        let formValueExtracted = formValue

        if (formValue && typeof formValue === 'string' && formValue.includes('.')) {
          formValueExtracted = formValue.split('.')
        }

        const config = {
          key: fieldKey,
          formValue: formValueExtracted,
          return: returnValue,
          returnValueProps: returnValueProps
        }
        autocompleteConfig.push(config)
      }
    },

    async handleFilesConversion (tab, resultFormData) {
      if (tab && tab.fileKeys && tab.fileKeys.length) {
        for (const fileKey of tab.fileKeys) {
          // If value is a file path
          if (resultFormData[tab.fileKeys[fileKey]] && typeof resultFormData[fileKey] === 'string') {
            const convertedToBlob = await convertFromPathToBlob(resultFormData[fileKey])
            const imagePathParts = resultFormData[fileKey].split('/')
            const existingImageName = imagePathParts[imagePathParts.length - 1]
            resultFormData[fileKey] = new File([convertedToBlob], existingImageName, { type: convertedToBlob.type })
          }
          // // If value is a new uploaded file in object form
          else if (resultFormData[fileKey] && isObject(resultFormData[fileKey]) && !resultFormData[fileKey].length) {
            let hasMoreProps = false
            for (const objectKey in resultFormData[fileKey]) {
              if (resultFormData[fileKey][objectKey] && isObject(resultFormData[fileKey][objectKey]) && resultFormData[fileKey][objectKey].data && resultFormData[fileKey][objectKey].name && resultFormData[fileKey][objectKey].type) {
                resultFormData[fileKey][objectKey] = new File([resultFormData[fileKey][objectKey].data], resultFormData[fileKey][objectKey].name, { type: resultFormData[fileKey][objectKey].type })
                hasMoreProps = true
              }
            }
            if (!hasMoreProps) {
              resultFormData[fileKey] = new File([resultFormData[fileKey].data], resultFormData[fileKey].name, { type: resultFormData[fileKey].type })
            }
          }
          // If there is some new uploaded files in an array
          else if (isArray(resultFormData[fileKey]) && resultFormData[fileKey].length) {
            for (const imageIndex in resultFormData[fileKey]) {
              if (resultFormData[fileKey][imageIndex]) {
                if (typeof resultFormData[fileKey][imageIndex] === 'string') {
                  const convertedToBlob = await convertFromPathToBlob(resultFormData[fileKey][imageIndex])
                  const imagePathParts = resultFormData[fileKey][imageIndex].split('/')
                  const existingImageName = imagePathParts[imagePathParts.length - 1]
                  resultFormData[fileKey][imageIndex] = new File([convertedToBlob], existingImageName, { type: convertedToBlob.type })
                }
                else if (typeof resultFormData[fileKey][imageIndex] === 'object') {
                  resultFormData[fileKey][imageIndex] = new File([resultFormData[fileKey][imageIndex].data], resultFormData[fileKey][imageIndex].name, { type: resultFormData[fileKey][imageIndex].type })
                }
              }
              // If value in array is null or undefined, remove them
              else {
                delete resultFormData[fileKey][imageIndex]
              }
            }
          }
        }
      }
    },

    refreshData (data) {
      if (data && data.data) {
        this.formDataForSend = data.data
        this.originalFormData = cloneDeep(data.data)
      }
      else if (data) {
        this.formDataForSend = data
        this.originalFormData = cloneDeep(data)
      }

      if (data && data.initialAutocompletes) {
        this.initialAutocompletes = data.initialAutocompletes
      }

      if (data && data.fields_config && !isEmpty(data.fields_config)) {
        this.fieldsConfig = data.fields_config
      }
    },

    applyFormValuesForSend (apiData) {
      const { tabContent } = this.tab || {}

      if (tabContent && tabContent.length) {
        const { hiddenFormKeys } = this.tab || {}

        return tabContent.reduce((acc, tabItem) => {
          const { type, key, rows } = tabItem || {}

          if (rows && rows.length) {
            if (hiddenFormKeys) {
              for (const hiddenKey in hiddenFormKeys) {
                acc[hiddenFormKeys[hiddenKey]] = apiData[hiddenFormKeys[hiddenKey]]
              }
            }

            if (type && type === 'dynamic-columns' && key && Object.prototype.hasOwnProperty.call(apiData, key)) {
              acc[tabItem.key] = apiData[tabItem.key]
            }
            else {
              rows.forEach(row => {
                row.fields
                  .filter(field =>
                    field &&
                    field.key &&
                    field.creatable &&
                    Object.prototype.hasOwnProperty.call(apiData, field.key)
                  )
                  .forEach(field => {
                    acc[field.key] = apiData[field.key]
                  })
              })
            }
          }
          else if (type && type === 'dynamic-columns') {
            const { key } = tabItem || {}
            if (key) {
              acc[key] = apiData[key]
            }
          }
          return acc
        }, {})
      }
    },

    clearValidationErrors (key) {
      this.$delete(this.validationErrors, key)
    }
  }
}
</script>

<style scoped lang="scss">
.spacer {
  height: 0;
  margin: 0;
  flex-grow: 0 !important;
}

.tab-content-div {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
}

.tab-content-card--table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
</style>
