<template>
  <tabs-layout
    :config="config"
    :refresh-tabs="tabsForRefresh"
    :first-tab-key="$route.query.open_tab_key"
    :redirect-view-name="'MachineOperation'"
  >
    <template
      #dynamic-content
    >
      <signature-dialog
        v-if="signatureDialogData.show"
        :show-dialog="signatureDialogData.show"
        :config="signatureDialogData.config"
        :fetch-signature="signatureDialogData.fetchSignature"
        :signature-id="signatureDialogData.signatureId"
        :signature-type="signatureDialogData.signatureType"
        :instance="signatureDialogData.instance"
        :tab-key="signatureDialogData.tabKey"
        @close="closeSignatureDialog"
        @refresh-tabs="refreshInstance"
      />

      <send-for-signature-dialog
        v-if="sendForSignatureDialogData.show"
        :show-dialog="sendForSignatureDialogData.show"
        :title="sendForSignatureDialogData.title"
        :document-id="sendForSignatureDialogData.documentId"
        :api-config="sendForSignatureDialogData.apiConfig"
        :signature-type="sendForSignatureDialogData.signatureType"
        :additional-object="sendForSignatureDialogData.additionalObject"
        :instance="sendForSignatureDialogData.instance"
        :tab-key="sendForSignatureDialogData.tabKey"
        @close="onCloseSendForSignatureDialog"
        @refresh-tabs="refreshInstance"
      />

      <change-status
        v-if="changeStatusDialogConfig.show"
        :show-dialog="changeStatusDialogConfig.show"
        :title="changeStatusDialogConfig.title"
        :statuses="changeStatusDialogConfig.statuses"
        :api-config="changeStatusDialogConfig.apiConfig"
        :document-id="changeStatusDialogConfig.document_id"
        :current-status-id="changeStatusDialogConfig.current_status"
        :callback="changeStatusDialogConfig.callback"
        :close-dialog-callback="closeChangeStatusDialog"
      />

      <information-dialog
        v-if="informationDialogData.show"
        :open-dialog="informationDialogData.show"
        :dialog-message="informationDialogData.message"
        :negative-button-text="informationDialogData.cancelText"
        :positive-button-text="informationDialogData.confirmText"
        :title="informationDialogData.title"
        :confirm-callback-function="informationDialogData.callback"
        @close="onCloseInformationDialog"
      />
    </template>
  </tabs-layout>
</template>

<script>
import SignatureDialog from '@/modules/fleet-module/components/SignatureDialog.vue'
import tabsLayout from '@/global/components/view-layouts/tabs-layout/views/TabsLayout.vue'
import { api } from '@/global/services/api'
import ChangeStatus from '@/modules/fleet-module/components/ChangeStatus.vue'
import SendForSignatureDialog from '@/modules/fleet-module/components/SendForSignatureDialog.vue'
import InformationDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/InformationDialog.vue'
import basicInfoConfig from '../js/edit/basicInfoConfig'
import vehicleOperationConfig from '../js/edit/vehicleOperationConfig'
export default {
  name: 'DispositionDetailsView',

  // Configuration for each tab is moved in separate js files
  mixins: [
    basicInfoConfig,
    vehicleOperationConfig
  ],

  components: {
    SendForSignatureDialog,
    ChangeStatus,
    SignatureDialog,
    tabsLayout,
    InformationDialog
  },

  data () {
    return {
      changeStatusDialogConfig: {
        show: false,
        apiConfig: {
          module: 'fleet',
          route: 'travel-documents/change-status',
          method: 'post'
        },
        getStatusesApiConfig: {
          module: 'fleet',
          route: 'travel-documents/statuses',
          method: 'get'
        },
        title: this.$t('fleet/documents/prl_cargo.select_new_status'),
        statuses: [],
        document_id: null,
        callback: null,
        current_status: null
      },
      tabsForRefresh: []
    }
  },

  computed: {
    config () {
      return {
        tabs: [
          // Basic info tab
          this.basicInfoConfig,
          // Vehicle operation data tab
          this.vehicleOperationConfig
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.disposition')
            },
            {
              text: this.$route.query.bread_crumb_value ? this.$t('fleet/documents/machine.disposition_document_number') + ' - ' + this.$route.query.bread_crumb_value : this.$t('fleet/documents/prl_cargo.document_edit'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  // These methods are common for all tabs
  methods: {
    async onChangeStatus (event) {
      let currentFetchedStatusId = null

      if (event?.formData?.status) {
        currentFetchedStatusId = event.formData.status.id
      }

      if (event?.data?.status) {
        currentFetchedStatusId = event.data.status
      }

      this.$set(this.changeStatusDialogConfig, 'current_status', currentFetchedStatusId)
      if (this.changeStatusDialogConfig.statuses.length) {
        this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
        this.$set(this.changeStatusDialogConfig, 'callback', () => this.changeStatusCallback())
        this.$set(this.changeStatusDialogConfig, 'show', true)
      }
      else {
        const { method, module, route } = this.changeStatusDialogConfig?.getStatusesApiConfig || {}

        if (method && module && route) {
          const { data } = await api()[module][method](route)
          if (data && data.length) {
            this.$set(this.changeStatusDialogConfig, 'statuses', data)
            this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
            this.$set(this.changeStatusDialogConfig, 'callback', () => this.changeStatusCallback())
            this.$set(this.changeStatusDialogConfig, 'show', true)
          }
        }
      }
    },

    changeStatusCallback () {
      const tabsForRefreshLocal = []
      tabsForRefreshLocal.push('basic-info')
      tabsForRefreshLocal.push('vehicle-operation-data')

      this.tabsForRefresh = tabsForRefreshLocal
    },

    async closeChangeStatusDialog () {
      this.$set(this.changeStatusDialogConfig, 'show', false)
    }
  }
}
</script>
