<template>
  <div>
    <v-dialog
      v-model="canShowDialog"
      max-width="560"
      persistent
      @keydown.esc="closeDialog(true)"
      @keydown.enter="sendSignature"
    >
      <v-card
        class="v-card"
      >
        <v-card-title
          class="v-card-title"
        >
          <universal-button
            type="back"
            color="transparent"
            style="border: none !important;"
            class="mr-2"
            :append-icon-style="{ fontSize: '35px !important' }"
            @click="closeDialog(false)"
          />
          {{ $t('base.enter_pin') }}
          <v-spacer/>

          <div
            class="x-image"
          >
            <v-img
              src="/img/icons/x-icon-image.svg"
              contain
              width="30"
              height="30"
              @click="closeDialog(true)"
            />
          </div>
        </v-card-title>
        <v-card-text
          class="pt-5 v-card-tex m-0t"
        >
          <v-overlay
            v-if="showLoader"
            style="position: absolute;"
            opacity="-0.2"
            absolute
          >
            <v-progress-circular
              indeterminate
              color="#000000"
              size="30"
            />
          </v-overlay>
          <text-field
            v-model="enteredPin"
            :field="field"
            :validation-errors="validationErrors"
            :disabled="showLoader"
            autofocus
            @clear-validation-errors="validationErrors = {}"
          />
        </v-card-text>
        <v-card-actions
          class="v-card-actions"
        >
          <v-row
            style="margin: 0;"
          >
            <v-col>
              <universal-button
                type="cancel"
                class="universal-buttons"
                :disabled="showLoader"
                @click="closeDialog(true)"
              />
            </v-col>
            <v-col>
              <universal-button
                type="save"
                :label="$t('base.confirm')"
                class="universal-buttons"
                :disabled="showLoader"
                @click="sendSignature"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextField from '../../../global/components/view-layouts/tabs-layout/components/fields/TextField.vue'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import { api } from '@/global/services/api'
import { isEmpty } from 'lodash'

export default {
  name: 'EnterPinDialog',

  components: {
    TextField,
    UniversalButton
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    signatureType: {
      type: String,
      default: ''
    },
    signatureId: {
      type: Number,
      default: null
    },
    instance: {
      type: Object,
      default: null
    },
    additionalObject: {
      type: Object,
      default: () => ({})
    },
    tabKey: {
      type: String,
      default: ''
    }
  },

  emits: [
    'close',
    'refresh-instance',
    'refresh-tabs'
  ],

  data () {
    return {
      field: {
        key: 'pin',
        label: this.$t('base.pin_code'),
        field_type: 'password'
      },
      enteredPin: null,
      showLoader: false,
      validationErrors: {}
    }
  },

  computed: {
    canShowDialog () {
      return this.showDialog
    }
  },

  methods: {
    closeDialog (closeAll) {
      this.$emit('close', closeAll)
    },

    async sendSignature () {
      try {
        this.showLoader = true
        const { module, method, route } = this.config?.apiConfig?.post || {}

        if (module && method && route && this.signatureId) {
          const formData = new FormData()
          formData.append('signature_id', this.signatureId.toString())
          formData.append('travel_document_id', this.$route.params.id)
          formData.append('pin', this.enteredPin)
          formData.append('signature_type', this.signatureType)

          if (!isEmpty(this.additionalObject)) {
            for (const key in this.additionalObject) {
              formData.append(key, this.additionalObject[key] ?? '')
            }
          }

          const { data } = await api()[module][method](route, formData)
          // Check if user is successfully signed document
          if (data && data.signed) {
            this.$emit('close', true)
            this.$emit('refresh-tabs', this.tabKey)

            if (this.instance) {
              this.$emit('refresh-instance', this.instance)
            }
          }
          // TODO: Otherwise, document is not successfully signed, push notification
          else {
          }
        }
      }
      catch (exception) {
        console.log(exception)
      }
      finally {
        this.showLoader = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-card {
  max-width: 560px;
  border-radius: 16px;
}

.v-card-text {
  position: relative;
  background-color: #FFFFFF;
  padding: 2% 2% 0 2% !important;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px;
  color: #111827 !important;
  border-bottom: 1px solid #E5E7EB;
}

.v-card-actions {
  font-size: 20px;
  border-top: 1px solid #E5E7EB;
  padding: 0 2% 0 2% !important;
}

.universal-buttons {
  width: 100%;
  height: 40px !important;
}

.x-image:hover {
  cursor: pointer;
}
</style>
