<template>
  <div>
    <v-dialog
      v-model="canShowDialog"
      content-class="elevation-0"
      width="560"
      persistent
      @keydown.esc="closeDialog(true)"
      @keydown.enter="onContinue"
    >
      <v-card
        class="v-card"
      >
        <v-card-title
          class="v-card-title"
        >
          {{ config && config.title ? config.title : '' }}
          <v-spacer/>

          <div
            class="x-image"
          >
            <v-img
              src="/img/icons/x-icon-image.svg"
              contain
              width="30"
              height="30"
              @click="closeDialog(true)"
            />
          </div>
        </v-card-title>

        <v-card-text
          class="v-card-text"
        >
          <v-row
            style="margin: 0;"
          >
            <v-col
              cols="6"
            >
              <div
                :class="getOldSignatureChooserDivClasses()"
                @click="onOldSignatureClick"
              >
                <v-overlay
                  v-if="existingSignatureLoader"
                  style="position: absolute;"
                  opacity="-0.2"
                  absolute
                >
                  <v-progress-circular
                    indeterminate
                    color="#FFFFFF"
                    size="30"
                  />
                </v-overlay>
                <div
                  :class="activeSignatureType === 'old' ? 'old-chooser-image mr-2' : 'old-chooser-image old-chooser-image--inactive mr-2'"
                >
                  <v-img
                    class="old-chooser-image-icon"
                    src="/img/icons/existing-signature-image.svg"
                    contain
                  />
                </div>
                <span>
                {{ signatureOldMessage }}
              </span>
              </div>
            </v-col>
            <v-col
              cols="6"
            >
              <div
                :class="activeSignatureType === 'new' ? 'signature-chooser-div signature-chooser-div--active' : 'signature-chooser-div'"
                @click="activeSignatureType = 'new'"
              >
                <div
                  :class="activeSignatureType === 'new' ? 'old-chooser-image mr-2' : 'old-chooser-image old-chooser-image--inactive mr-2'"
                >
                  <v-img
                    class="old-chooser-image-icon"
                    src="/img/icons/new-signature-image.svg"
                    contain
                  />
                </div>
                <span>
                 {{ $t('base.upload_new_signature') }}
              </span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions
          class="v-card-actions"
        >
          <v-row
            style="margin: 0;"
          >
            <v-col>
              <universal-button
                type="cancel"
                class="universal-buttons"
                @click="closeDialog(true)"
              />
            </v-col>
            <v-col>
              <universal-button
                type="save"
                :label="$t('base.continue')"
                class="universal-buttons"
                @click="onContinue"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    Enter pin dialog shows when user chose pin from profile and clicks 'continue'-->
    <enter-pin-dialog
      v-if="showEnterPinDialog"
      :show-dialog="showEnterPinDialog"
      :config="config"
      :signature-type="signatureType"
      :signature-id="fetchedSignatureId"
      :instance="instance"
      :additional-object="additionalObject"
      :tab-key="tabKey"
      @close="closeEnterPinDialog"
      @refresh-tabs="data => $emit('refresh-tabs', data)"
      @refresh-instance="instanceValue => $emit('refresh-instance', instanceValue)"
    />

    <create-signature-dialog
      v-if="showUploadSignatureDialog"
      :show-dialog="showUploadSignatureDialog"
      :config="config"
      :signature-type="signatureType"
      :instance="instance"
      :tab-key="tabKey"
      :additional-object="additionalObject"
      @close="onCloseUploadSignature"
      @refresh-tabs="data => $emit('refresh-tabs', data)"
      @refresh-instance="instanceValue => $emit('refresh-instance', instanceValue)"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import EnterPinDialog from './EnterPinDialog.vue'
import { api } from '@/global/services/api'
import CreateSignatureDialog from './CreateSignatureDialog.vue'

export default {
  name: 'SignatureDialog',

  components: {
    CreateSignatureDialog,
    EnterPinDialog,
    UniversalButton
  },

  emits: [
    'close',
    'refresh-instance',
    'refresh-tabs'
  ],

  props: {
    showDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    signatureId: {
      type: Number,
      default: null
    },
    fetchSignature: {
      type: Boolean,
      default: false
    },
    signatureType: {
      type: String,
      default: ''
    },
    instance: {
      type: Object,
      default: null
    },
    additionalObject: {
      type: Object,
      default: () => ({})
    },
    tabKey: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      canShowDialog: false,
      existingSignatureLoader: false,
      activeSignatureType: 'old',
      signatureOldMessage: this.$t('base.use_signature_from_profile'),
      disableSignatureOld: false,
      showEnterPinDialog: false,
      defaultSignatureData: {
        id: null,
        image: null,
        pin: null,
        save: false
      },
      fetchedSignatureId: null,
      showUploadSignatureDialog: false
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (canShow) {
        this.canShowDialog = canShow
      }
    }
  },

  async created () {
    await this.fetchExistingSignature()
  },

  methods: {
    closeDialog (closeAll = false) {
      this.$emit('close', closeAll)
    },

    closeEnterPinDialog (closeAll = false) {
      this.showEnterPinDialog = false
      if (closeAll) {
        this.closeDialog(closeAll)
      }
    },

    onContinue () {
      if (this.activeSignatureType === 'new') {
        this.showUploadSignatureDialog = true
      }
      else {
        this.showEnterPinDialog = true
      }
    },

    // Fetch existing signature
    async fetchExistingSignature () {
      this.existingSignatureLoader = true

      try {
        if (this.fetchSignature) {
          // Validate that API config is available
          if (!this.config || isEmpty(this.config.apiConfig)) {
            return this.setExistingSignatureNotFound()
          }

          const { get: { module, method, route } } = this.config.apiConfig

          // Fetch existing signature data
          const { data } = await api()[module][method](route)

          if (!isEmpty(data)) {
            const { signatureId } = data?.person || {}
            if (signatureId) {
              this.activeSignatureType = 'old'
              this.fetchedSignatureId = signatureId
            }
            else {
              this.setExistingSignatureNotFound()
            }
          }
          else {
            this.setExistingSignatureNotFound()
          }
        }
        else {
          if (this.signatureId !== null) {
            this.activeSignatureType = 'old'
          }
          else {
            this.setExistingSignatureNotFound()
          }
        }
      }
      catch (error) {
        // Log error and set signature not found state
        console.error('Error fetching signature:', error)
        this.setExistingSignatureNotFound()
      }
      finally {
        this.existingSignatureLoader = false
      }
    },

    onCloseUploadSignature (closeAll = false) {
      this.showUploadSignatureDialog = false
      if (closeAll) {
        this.closeDialog(closeAll)
      }
    },

    getOldSignatureChooserDivClasses () {
      let classes = 'signature-chooser-div'

      if (this.activeSignatureType === 'old') {
        classes += ' signature-chooser-div--active'
      }

      if (this.existingSignatureLoader) {
        classes += ' signature-chooser-div--disabled'
      }

      if (this.disableSignatureOld) {
        classes += ' signature-chooser-div--disabled'
      }

      return classes
    },

    setExistingSignatureNotFound () {
      this.signatureOldMessage = this.$t('base.signature_not_found')
      this.disableSignatureOld = true
      this.activeSignatureType = 'new'
    },

    onOldSignatureClick () {
      if (!this.disableSignatureOld) {
        this.activeSignatureType = 'old'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-card {
  max-width: 560px;
  border-radius: 16px;
}

.v-card-text {
  background-color: #FFFFFF;
  padding: 2% 2% 0 2% !important;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px;
  color: #111827 !important;
  border-bottom: 1px solid #E5E7EB;
}

.v-card-actions {
  font-size: 20px;
  border-top: 1px solid #E5E7EB;
  padding: 0 2% 0 2% !important;
}

.universal-buttons {
  width: 100%;
  height: 40px !important;
}

.signature-chooser-div {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 80px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #111827;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.signature-chooser-div--active {
  border: 1px solid #65AD45;
  background-color: #F7FEE7;
}

.signature-chooser-div--disabled {
  border: 1px solid #9c9d9a;
  background-color: #9c9d9a;
  cursor: not-allowed;
}

.old-chooser-image {
  width: 64px;
  height: 64px;
  align-content: center;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.old-chooser-image--inactive {
  background-color: #F3F4F6;
}

.old-chooser-image-icon {
  color: #000000;
  font-size: 30px;
}

.x-image:hover {
  cursor: pointer;
}
</style>
