<template>
<div>
  <v-dialog
    v-model="canShowDialog"
    content-class="elevation-0"
    width="560"
    persistent
    @keydown.esc="closeDialog"
    @keydown.enter="sendStatus"
  >
    <v-card
      class="v-card"
    >
      <v-card-title
        class="v-card-title"
      >
        {{ title ? title : '' }}
        <v-spacer/>

        <div
          class="x-image"
        >
          <v-img
            src="/img/icons/x-icon-image.svg"
            contain
            width="30"
            height="30"
            @click="closeDialog"
          />
        </div>
      </v-card-title>

      <v-card-text
        class="v-card-text"
      >
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <v-list
              style="padding: 8px;"
            >
              <v-list-item
                v-for="(status, statusIndex) in statuses"
                :key="statusIndex"
                class="list-item"
                @click="onSelectedStatus(status)"
              >
                <v-list-item-action
                  class="mr-1"
                >
                  <label
                    class="container"
                  >
                    <input
                      type="checkbox"
                      :checked="selectedStatusId === status.id"
                    >
                    <v-icon
                      :style="{ border: selectedStatusId === status.id ? '1px solid #65AD45' : '1px solid gray', borderRadius: '50%',  color: selectedStatusId === status.id ? '#65AD45' : '', paddingLeft: '1px', fontSize: '16px', width: '20px', height: '20px' }"
                    >
                      {{ selectedStatusId === status.id ? 'mdi-circle' : '' }}

                    </v-icon>
                  </label>
                </v-list-item-action>

                <v-list-item-title
                  class="list-title"
                >
                  {{ status && status.description ? $t(status.description) : '' }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions
        class="v-card-actions"
      >
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <universal-button
              type="cancel"
              class="universal-buttons"
              @click="closeDialog"
            />
          </v-col>
          <v-col>
            <universal-button
              type="save"
              :label="$t('base.send')"
              class="universal-buttons"
              @click="sendStatus"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import { api } from '@/global/services/api'
import store from '../../../global/store'

export default {
  name: 'ChangeStatusDialog',

  components: {
    UniversalButton
  },

  emits: [
    'close',
    'refresh-instance'
  ],

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    statuses: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      default: ''
    },
    apiConfig: {
      type: Object,
      default: () => ({})
    },
    currentStatusId: {
      type: Number,
      default: null
    },
    documentId: {
      type: [Number, String],
      default: null
    },
    callback: {
      type: Function,
      default: () => {}
    },
    closeDialogCallback: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      canShowDialog: false,
      selectedStatusId: null
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (val) {
        this.canShowDialog = val
      }
    },
    statuses: {
      immediate: true,
      deep: true,
      handler (newStatuses) {
        if (newStatuses && newStatuses.length) {
          this.$nextTick(() => {
            if (this.currentStatusId) {
              this.selectedStatusId = this.currentStatusId
            }
            else {
              this.selectedStatusId = newStatuses[0]?.id
            }
          })
        }
      }
    }
  },

  methods: {
    closeDialog () {
      if (typeof this.closeDialogCallback === 'function') {
        this.closeDialogCallback()
      }
    },

    async sendStatus () {
      try {
        const { module, method, route } = this.apiConfig || {}

        if (module && method && route) {
          if (this.selectedStatusId) {
            const formData = new FormData()
            formData.append('_method', 'PATCH')
            formData.append('id', this.selectedStatusId)
            const modifiedRoute = route + '/' + this.documentId

            await api()[module][method](
              modifiedRoute,
              formData
            )

            if (typeof this.callback === 'function') {
              this.callback()
            }

            this.closeDialog()
          }
        }
        else {
          this.pushNotification(this.$t('base.invalid_api_configuration_save'))
        }
      }
      catch (exception) {
        console.log(exception)
      }
    },

    onSelectedStatus (status) {
      const { id } = status || {}
      this.selectedStatusId = id
    },

    // Helper method to push notifications
    pushNotification (message) {
      store.dispatch('base/notifications/push', message)
    }
  }
}
</script>

<style scoped lang="scss">
.universal-buttons {
  width: 100%;
  height: 40px !important;
}

.v-card-text {
  padding: 0 !important;
}

.v-card {
  border-radius: 16px;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 20px !important;
  border-bottom: 1px solid #E5E7EB;
}

.container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.x-image:hover {
  cursor: pointer;
}

.list-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #111827 !important;
}

.list-item {
  height: 30px;
}

.list-item:hover {
  cursor: pointer;
}
</style>
